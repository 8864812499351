import { useState } from "react";
import { WorkExperience } from "./data";
import "./work.css";
import Heading from "./Heading";

function WorkDataleft({ title, company, date }) {
  return (
    <div className="timeline__item left">
      <div className="content">
        <div className="work__title">{title}</div>
        <div className="work__company">{company}</div>
        <div className="work__date">{date}</div>
      </div>
    </div>
  );
}
function WorkDataright({ title, company, date }) {
  return (
    <div className="timeline__item right">
      <div className="content">
        <div className="work__title">{title}</div>
        <div className="work__company">{company}</div>
        <div className="work__date">{date}</div>
      </div>
    </div>
  );
}
export default function Work() {
  return (
    <div className="container">
      <Heading title="Work Experience" />
      <div className="timeline">
        {WorkExperience.map((work, index) => {
          return index % 2 === 0 ? (
            <WorkDataleft
              key={index}
              title={work.title}
              company={work.institution}
              date={work.duration}
            />
          ) : (
            <WorkDataright
              key={index}
              title={work.title}
              company={work.institution}
              date={work.duration}
            />
          );
        })}
      </div>
    </div>
  );
}
