export const projectList = [
  {
    id: 0,
    image: "Logos/Gesture_drone.jpg",
    name: "Gesture Controlled Drone",
    description:
      "Developed software for a drone system controlled via gestures. Utilizing Python, OpenCV, and Media Pipe libraries, it interprets hand gestures in real-time, enabling seamless communication between users and the drone.",
  },
  {
    id: 1,
    image: "Logos/Autonomous_vehicle.png",
    name: "Autonomous vehicle",
    description:
      "Enhanced vehicle's companion computer with Raspberry Pi 4 for sensor processing, motor control, speed adjustment, and MAVlink connection to FMU. Implemented obstacle detection and speed management logic.",
  },
  {
    id: 2,
    image: "Logos/Software_defined_radio.jpg",
    name: "Software Defined Radio",
    description:
      "Developed and evaluated a cutting-edge SDR system on Raspberry Pi 4 using C++ and Python for real-time FM audio data with dynamic sampling frequency adjustments.",
  },
  {
    id: 3,
    image: "Logos/Image_decompression.png",
    name: "Hardware Image Decompressor",
    description:
      "Implemented hardware-based image decompression on Altera Cyclone II FPGA, featuring lossless decoding, dequantization, signal transform, and color space conversion. Utilized UART for data transmission, RAM for storage, and VGA for display.",
  },
  {
    id: 4,
    image: "Logos/Pacemaker_final.png",
    name: "Pacemaker",
    description:
      "Developed a pacemaker system on FRDM-K64F ARM board using Simulink. Operates in AOO, VOO, AAI modes with rate-adaptive functionality. Designed a DCM with Flask and Python tkinter for intuitive control",
  },
  {
    id: 5,
    image: "Logos/Mapping.jpeg",
    name: "3D Spatial Mapping",
    description:
      "Developed a 3D LIDAR room scanner using MSP432E401Y and VL53L1X ToF sensor for indoor mapping, data acquisition, and visualization via Python and Open3D, aiding robotics navigation and layout mapping.",
  },
];
export const Skill_data = [
  {
    category: "Languages",
    skills: [
      {
        image: "Logos/python.png",
        name: "Python",
      },
      {
        image: "Logos/cpp.png",
        name: "C & C++",
      },

      {
        image: "Logos/java.png",
        name: "Java",
      },
      {
        image: "Logos/verilog.svg",
        name: "System Verilog",
      },
    ],
  },
  {
    category: "Web",
    skills: [
      {
        image: "Logos/js.png",
        name: "JavaScript",
      },
      {
        image: "Logos/typescript.svg",
        name: "TypeScript",
      },
      {
        image: "Logos/css.webp",
        name: "CSS",
      },
      {
        image: "Logos/html5.svg",
        name: "HTML",
      },
      {
        image: "Logos/nextjs.svg",
        name: "Next.js",
      },
      {
        image: "Logos/react.png",
        name: "React",
      },
      {
        image: "Logos/mongodb.svg",
        name: "MongoDB",
      },
    ],
  },
  {
    category: "Leadership",
    skills: [
      {
        image: "Logos/team.svg",
        name: "Team Building",
      },
      {
        image: "Logos/conflict.png",
        name: "Conflict Resolution",
      },
      {
        image: "Logos/problem-solving.svg",
        name: "Problem Solving",
      },
      {
        image: "Logos/optimization.svg",
        name: "Process Optimization",
      },
    ],
  },
];
export const skillsList = [
  {
    image: "Logos/python.png",
    name: "Python",
  },
  {
    image: "Logos/cpp.png",
    name: "C++",
  },
  {
    image: "Logos/java.png",
    name: "Java",
  },
  {
    image: "Logos/js.png",
    name: "JavaScript",
  },
  {
    image: "Logos/react.png",
    name: "React",
  },
  {
    image: "Logos/css.webp",
    name: "CSS",
  },
  {
    image: "Logos/flask.png",
    name: "Flask",
  },
];

export const WorkExp = [
  {
    img: "Logos/mcmaster.webp",
    companyName: "McMaster University",
    title: "Undergraduate TA",
    description:
      "Assisted in the delivery of course material, conducted tutorials, and graded assignments for the course.",
    duration: "January 2024 - April 2024",
  },
  {
    img: "Logos/amazon2.svg",
    companyName: "Amazon",
    title: "Program Manager Intern",
    description:
      "Managed a team of 30+ employees, ensuring efficient operations and maintaining a safe work environment.",
    duration: "May 2023 - August 2023",
  },
  {
    img: "Logos/amd2.svg",
    companyName: "AMD",
    title: "ASIC Design and Verification Engineer",
    description:
      "Developed and verified ASIC designs, ensuring compliance with industry standards and requirements.",
    duration: "May 2022 - April 2023",
  },
  {
    img: "Logos/mcmaster.webp",
    companyName: "McMaster University",
    title: "Undergraduate TA",
    description:
      "Assisted in the delivery of course material, conducted tutorials, and graded assignments for the course.",
    duration: "January 2022 - April 2022",
  },
  {
    img: "Logos/mcmaster.webp",
    companyName: "McMaster University",
    title: "MES Mentor",
    description:
      "Mentored first and second year students in the computer Engineering and Management program, providing academic and career guidance.",
    duration: "September 2021 - December 2021",
  },
];

export const WorkExperience = [
  {
    institution: "Amazon",
    title: "Software Engineer",
    duration: "Summer 2023",
  },
  {
    institution: "CGI",
    title: "Software Engineer",
    duration: "Winter 2022",
  },

  {
    institution: "AMD",
    title: "Software Engineer",
    duration: "Summer & Fall 2022",
  },
  {
    institution: "McMaster University",
    title: "Undergrad TA",
    duration: "Winter 2021",
  },
  {
    institution: "McMaster University",
    title: "MES Mentor",
    duration: "Fall 2021",
  },
];
