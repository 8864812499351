import { useState } from "react";
import { projectList } from "./data";
import "./Projects.css";
import Heading from "./Heading";
import { motion, AnimatePresence } from "framer-motion";

function Project({ name, description, image, direction }) {
  return (
    <AnimatePresence>
      <motion.div
        key={image}
        initial={
          direction === "prev"
            ? { opacity: 0, x: -200 }
            : { opacity: 0, x: 200 }
        }
        animate={{ opacity: 1, x: 0 }}
        exit={
          direction === "prev"
            ? { opacity: 0, x: 200 }
            : { opacity: 0, x: -200 }
        }
        transition={{ duration: 0.3 }} // Adjust duration (in seconds)
        className="project"
      >
        <div className="project__image">
          <img className="project__image-img" src={image} alt={name} />
        </div>
        <div className="project__text">
          <h2 className="project__text-heading">{name}</h2>
          <p className="project__text-description">{description}</p>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default function ProjectsContainer() {
  const [index, setIndex] = useState(0);
  const project = projectList[index];
  const [direction, setDirection] = useState(null); // State to track direction

  function NavigationButtons({ isPrevDisabled, isNextDisabled }) {
    function handlePrev() {
      setDirection("prev");
      setIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }

    function handleNext() {
      setDirection("next");
      setIndex((prevIndex) => Math.min(prevIndex + 1, projectList.length - 1));
    }

    return (
      <div className="button-container">
        <button
          className={`navigation-buttons ${isPrevDisabled ? "disabled" : ""}`}
          onClick={handlePrev}
          disabled={isPrevDisabled}
        >
          <div className="navigation__buttons--icon">
            <img
              src="Logos/previous.svg"
              alt="previous icon"
              className="navigation__buttons--icon"
            />
          </div>
        </button>
        <button
          className={`navigation-buttons ${isNextDisabled ? "disabled" : ""}`}
          onClick={handleNext}
          disabled={isNextDisabled}
        >
          <img
            src="Logos/nexticon.svg"
            alt="next icon"
            className="navigation__buttons--icon"
          />
        </button>
      </div>
    );
  }

  return (
    <div className="container row">
      <Heading title="Projects" />

      <NavigationButtons
        isPrevDisabled={index === 0}
        isNextDisabled={index === projectList.length - 1}
      />
      <div className="project-container">
        <Project
          key={project.id}
          name={project.name}
          description={project.description}
          image={project.image}
          direction={direction}
        />
      </div>
    </div>
  );
}

// function Project({ name, description, image }) {
//   return (
//     <div className="project">
//       <div className="project__image">
//         <img className="project__image-img" src={image} alt={name} />
//       </div>
//       <div className="project__text">
//         <h2 className="project__text-heading">{name}</h2>
//         <p className="project__text-description">{description}</p>
//       </div>
//     </div>
//   );
// }

// export default function Projects_container() {
//   let [index, setIndex] = useState(0);
//   const project = projectList[index];

//   function NavigationButtons({ isPrevDisabled, isNextDisabled }) {
//     return (
//       <div className="button-container">
//         <button
//           className={`navigation-buttons ${isPrevDisabled ? "disabled" : ""}`}
//           onClick={() => setIndex(index - 1)}
//           disabled={isPrevDisabled}
//         >
//           <div className="navigation__buttons--icon">
//             <img
//               src="Logos/previous.svg"
//               alt="previous icon"
//               className="navigation__buttons--icon"
//             />
//           </div>
//         </button>
//         <button
//           className={`navigation-buttons ${isNextDisabled ? "disabled" : ""}`}
//           onClick={() => setIndex(index + 1)}
//           disabled={isNextDisabled}
//         >
//           <img
//             src="Logos/nexticon.svg"
//             alt="previous icon"
//             className="navigation__buttons--icon"
//           />
//         </button>
//       </div>
//     );
//   }

//   return (
//     <div className="container row ">
//       <Heading title="Projects" />

//       <NavigationButtons
//         isPrevDisabled={index === 0}
//         isNextDisabled={index === projectList.length - 1}
//       />
//       <div className="project-container">
//         <Project
//           key={project.id}
//           name={project.name}
//           description={project.description}
//           image={project.image}
//         />
//       </div>
//     </div>
//   );
// }
