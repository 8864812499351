import { Skill_data } from "./data";
import Heading from "./Heading";
import "./Newskill.css";
function SkillCard({ image, name }) {
  return (
    <div className="skill__card">
      <div className="skill__card--image">
        <img className="skill__card--img" src={image} alt={name} />
      </div>
      <div className="skill__card--label">{name}</div>
    </div>
  );
}
export default function SkillsComponent() {
  return (
    <div className="container">
      <Heading title="Skills" />
      <h1 className="skills__category--heading">Backend</h1>
      <div className="skill__category">
        <SkillCard
          image={Skill_data[0].skills[0].image}
          name={Skill_data[0].skills[0].name}
        />
        <SkillCard
          image={Skill_data[0].skills[1].image}
          name={Skill_data[0].skills[1].name}
        />

        <SkillCard
          image={Skill_data[0].skills[2].image}
          name={Skill_data[0].skills[2].name}
        />
        <SkillCard
          image={Skill_data[0].skills[3].image}
          name={Skill_data[0].skills[3].name}
        />
      </div>
      <h1 className="skills__category--heading">Frontend</h1>
      <div className="skill__category">
        <SkillCard
          image={Skill_data[1].skills[0].image}
          name={Skill_data[1].skills[0].name}
        />
        <SkillCard
          image={Skill_data[1].skills[1].image}
          name={Skill_data[1].skills[1].name}
        />
        <SkillCard
          image={Skill_data[1].skills[2].image}
          name={Skill_data[1].skills[2].name}
        />
        <SkillCard
          image={Skill_data[1].skills[3].image}
          name={Skill_data[1].skills[3].name}
        />
        <SkillCard
          image={Skill_data[1].skills[4].image}
          name={Skill_data[1].skills[4].name}
        />
        <SkillCard
          image={Skill_data[1].skills[5].image}
          name={Skill_data[1].skills[5].name}
        />
        <SkillCard
          image={Skill_data[1].skills[6].image}
          name={Skill_data[1].skills[6].name}
        />
      </div>
      <h1 className="skills__category--heading">Leadership</h1>
      <div className="skill__category">
        <SkillCard
          image={Skill_data[2].skills[0].image}
          name={Skill_data[2].skills[0].name}
        />
        <SkillCard
          image={Skill_data[2].skills[1].image}
          name={Skill_data[2].skills[1].name}
        />
        <SkillCard
          image={Skill_data[2].skills[2].image}
          name={Skill_data[2].skills[2].name}
        />
        <SkillCard
          image={Skill_data[2].skills[3].image}
          name={Skill_data[2].skills[3].name}
        />
      </div>
    </div>
  );
}
