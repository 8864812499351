import "./Ending.css";
import { Socials } from "./Intro.js";
export default function Ending() {
  return (
    <div className="hero">
      <div className="container">
        <div className="ending">
          <div class="ending__name ">Yuvraj Bal </div>
          <Socials className="align__left" />
          <h4 className="ending__copyright">
            @ Yuvraj Bal. All rights reserved
          </h4>
        </div>
      </div>
    </div>
  );
}
