import "./Navigation.css";

function NavigationButton({ text, target }) {
  const handleClick = () => {
    document.getElementById(target).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <button onClick={handleClick} className="nav-button">
      {text}
    </button>
  );
}
function toggleNav() {
  const navCol = document.querySelector(".nav-col");
  navCol.classList.toggle("nav-active");
}
export default function Navigation() {
  return (
    <div className="nav-bg">
      <div className="container nav__row">
        <button
          onClick={toggleNav}
          className="nav-toggle"
          aria-label="open-navigation"
        >
          <div className="harmburger__container">
            <img
              src="./Logos/hamburger.svg"
              alt="hamburger"
              className="hamburger--img"
            />
          </div>
        </button>
        <div className="nav-name">
          <h1>YB</h1>
        </div>
        <nav className="nav-col">
          <NavigationButton text="Home" target="home" />
          <NavigationButton text="About" target="aboutme" />
          <NavigationButton text="Work" target="work" />
          <NavigationButton text="Skills" target="skills" />
          <NavigationButton text="Projects" target="projects" />
        </nav>
      </div>
    </div>
  );
}
