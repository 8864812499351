import "./Intro.css";

export function Socials() {
  return (
    <div className="socials">
      <a
        href="https://www.linkedin.com/in/yuvraj-bal-606346193/"
        target="_blank"
        rel="noreferrer"
        className="socials__link"
      >
        <img
          className="socials__link-image"
          src="Logos/linkedin.svg"
          alt="linkedin"
        />
      </a>
      <a
        href="https://github.com/yuvrajbal"
        target="_blank"
        rel="noreferrer"
        className="socials__link"
      >
        <img
          className="socials__link-image"
          src="Logos/github.svg"
          alt="linkedin"
        />
      </a>
    </div>
  );
}

export default function Intro() {
  return (
    <div className="container">
      <div className="intro">
        <div className="intro__image">
          <img
            className="my-image"
            src="Logos/grad_pic.png"
            alt="graduation pic"
          />
        </div>
        <div className="intro__text">
          <h1 className="intro__text--name">Yuvraj Bal</h1>
          <h2 className="intro__text--designation">Software Engineer</h2>
          {/* <p className="intro__text--hobby">Creating the Future, Today</p> */}
          {/* <h2 className="intro__text--subheading"> About Me</h2>
          <p className="intro__text--aboutme">
            I graduated from McMaster University with a degree in Computer
            Engineering. I am passionate about technology and its applications
            in solving real-world problems. I am a quick learner and a team
            player.
          </p> */}
        </div>
      </div>
      <Socials />
    </div>
  );
}
