import Projects_container from "./Projects.js";
import SkillsComponent from "./Newskill.js";
import Work from "./Work.js";
import Intro from "./Intro.js";
import "./App.css";
import Navigation from "./Navigation.js";
import Aboutme from "./Aboutme.js";
import Ending from "./Ending.js";
function App() {
  return (
    <div className="App">
      <Navigation />
      <div id="home">
        <Intro />
      </div>
      <div id="aboutme">
        <Aboutme />
      </div>
      <div id="work">
        <Work />
      </div>
      <div id="skills">
        <SkillsComponent />
      </div>
      <div id="projects">
        <Projects_container />
      </div>
      <div id="ending">
        <Ending />
      </div>
    </div>
  );
}

export default App;
