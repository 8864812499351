import Heading from "./Heading";
import "./aboutme.css";
export default function Aboutme() {
  return (
    <div className="container">
      <Heading title="About Me" />
      <div className="aboutme">
        <div className="aboutme__image">
          <img
            className="aboutme--image"
            src="Logos/MyImage3.jpg"
            alt="graduation pic"
          />
        </div>
        <div className="aboutme__content">
          <p className="aboutme__content--text">
            I’m a software engineer passionate about using technology to solve
            real-world problems. I'm a quick learner, a team player, and always
            excited to take on new challenges.
            {/* I graduated from McMaster University with a degree in Computer
            Engineering. I am passionate about technology and its applications
            in solving real-world problems. I am a quick learner and a team
            player. */}
          </p>
          <div className="aboutme__content--resumebutton">
            <a
              href="./YuvrajBal_resume_sde.pdf"
              download="Yuvraj_Resume.pdf"
              className="resume-button"
            >
              Download Resume
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
